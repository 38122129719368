import styled from 'styled-components'

export const Item = styled.div`
  margin-bottom: 56px;
`

export const Wrapper = styled.div`
  position: relative;

  input,
  textarea {
    display: inline-block;
    width: 100%;
    border: 0;
    border-bottom: 1px solid #d9d9d9;
    background: transparent;
    font-family: Roboto;
    font-size: 14px;
    line-height: 26px;
    padding-bottom: 8px;

    &:focus {
      outline: 0;
    }
  }
`

export const Label = styled.label`
  position: absolute;
  top: 8px;
  left: 0;
  pointer-events: none;
  transition: bottom 200ms, font-size 200ms;
  font-family: Roboto;
  color: #9d9d9d;
  font-size: 14px;
  line-height: 26px;

  ${({ active }) =>
    active &&
    `
    top: -18px;
    font-size: 12px;
  `};
`

export const ErrorMessage = styled.div`
  color: red;
  font-family: Roboto;
  font-size: 12px;
  margin-top: 5px;
`