import React, { Component } from 'react'
import { Formik, Form, Field } from 'formik'
import axios from 'axios'

import FormItem from 'components/shared/FormItem'

import { Wrapper, FormTitle, ItemsRow, Item, SubmitButton } from './styled';

class ContactForm extends Component {
  state = {
    sent: false
  }

  preparePayload = ({ values: { name, email, message } }) => {
    const payload = {};

    payload.html = `
        <h3>Contact from website</h3>

        <p>
          <strong>Name: </strong>
          ${name}
        </p>
        <p>
          <strong>Email: </strong>
          ${email}
        </p>
        <p>
          <strong>Message:</strong>
          ${message}
        </p>
      `;

    payload.from = 'hello@metrotecherectors.com';
    payload.to = ['office@metro-techerectors.com'];
    payload.subject = 'Contact from website';

    return payload;
  };

  handleFormSubmit = async (values, { setSubmitting }) => {
    if (this.state.sent) {
      setSubmitting(false);
      return;
    }
    
    const payload = this.preparePayload({ values });

    try {
      await axios.post('https://9lsmko5w60.execute-api.us-east-2.amazonaws.com/prod/', payload);

      this.setState({
        sent: true
      });
    } catch (error) {
      console.log('error m8', error);
    } finally {
      setSubmitting(false);
    }
  };

  render() {
    return (
      <Wrapper>
        <FormTitle>Contact form</FormTitle>

        <Formik
          initialValues={{ name: '', email: '', message: '' }}
          validate={values => {
            let errors = {}
            if (!values.email) {
              errors.email = 'Please enter your email address'
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Please enter correct email address'
            }

            if (!values.name) {
              errors.name = 'Please enter your name'
            }

            if (!values.message) {
              errors.message = 'Please enter the message'
            }

            return errors
          }}
          onSubmit={this.handleFormSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <ItemsRow>
                <Item>
                  <Field
                    component={FormItem}
                    type="text"
                    name="name"
                    placeholder="Your name"
                  />
                </Item>

                <Item>
                  <Field
                    component={FormItem}
                    type="email"
                    name="email"
                    placeholder="Your email address"
                  />
                </Item>
              </ItemsRow>

              <Field
                component={FormItem}
                name="message"
                placeholder="Your message"
                componenttype="textarea"
                rows="1"
              />

              <SubmitButton type="submit" disabled={isSubmitting} sent={this.state.sent}>
                {this.state.sent ? 'Your message has been sent' : (isSubmitting ? 'Sending...' : 'Send')}
              </SubmitButton>
            </Form>
          )}
        </Formik>
      </Wrapper>
    )
  }
}

export default ContactForm;