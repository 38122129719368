import styled from 'styled-components'
import { media } from 'themes'

export const Wrapper = styled.div`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: -50px;
    left: -30px;
    width: calc(50vw + 110px);
    height: 800px;
    background: linear-gradient(0deg, #FFFFFF 0%, #F7F7F7 100%);
    z-index: -1;
    
    ${media.md`
      width: calc(100% + 60px);
    `}
  }
`

export const FormTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  margin-bottom: 48px;
`

export const ItemsRow = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.sm`
    display: block;
  `}
`

export const Item = styled.div`
  width: 270px;

  ${media.lg`
    width: 250px;
  `}

  ${media.sm`
    width: 100%;
  `}
`

export const SubmitButton = styled.button`
  width: 130px;
  height: 45px;
  background-color: #ffb400;
  border: 0;
  color: white;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-transform: uppercase;
  margin-bottom: 85px;
  float: right;

  ${({ disabled }) => disabled && `
    background: #DDD;
  `}

  ${({ sent }) => sent && `
    width: auto;
    padding: 0 20px;
  `}

  ${media.sm`
    float: none;
  `}
`
