import React from 'react'
import { Row, Col } from 'styled-bootstrap-grid'

import Container from 'components/shared/Container'
import SectionTitle from 'components/shared/SectionTitle'
import Text from 'components/shared/Text'

import ContactForm from '../ContactForm';
import { Address, ContactDetails, BluePane } from './styled'

const Header = () => (
  <Container relative>
    <Row>
      <Col lg={4}>
        <SectionTitle main>Contact</SectionTitle>
      </Col>
    </Row>
    <Row>
      <Col lg={4}>
        <Text main>Want to find out more? Get in touch! </Text>
        <Text>
          We are happy to walk you through our process, range of solutions,
          information on past projects, or answer any questions.
        </Text>
      </Col>
      <Col lg={7} lgOffset={1}>
        <Address>
          Metro-Tech Erectors Corp.
          <br />
          58-02 Maspeth Ave.
          <br />
          Maspeth, NY 11378
        </Address>

        <ContactDetails>
          <a href="tel:7183663024">718-366-3024</a>
          <br />
          <a href="mailto:office@metro-techerectors.com">
            office@metro-techerectors.com
          </a>
        </ContactDetails>

        <ContactForm />
      </Col>
    </Row>

    <BluePane />
  </Container>
)

export default Header
