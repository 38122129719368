import React from 'react'

import Layout from 'components/shared/Layout'
import Map from 'components/Contact/Map';
import Header from 'components/Contact/Header';

const Contact = () => (
  <Layout> 
    <Header />
    <Map />
  </Layout>
);

export default Contact
