import React from 'react'
import { MapFrame } from './styled'

const Map = () => (
  <MapFrame
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12095.111881643757!2d-73.9119279!3d40.722904!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe782dbd0164690ba!2sMETRO-TECH+ERECTORS+CORP!5e0!3m2!1spl!2smv!4v1543568131128"
    frameBorder="0"
    allowFullScreen
  />
)

export default Map
