import styled from 'styled-components'
import { colors, media } from 'themes'

export const Address = styled.div`
  font-size: 24px;
  line-height: 40px;
  font-weight: 500;
  margin-bottom: 32px;

  ${media.sm`
    font-size: 18px;
    line-height: 32px;
  `}
`

export const ContactDetails = styled.div`
  margin-bottom: 100px;

  a {
    color: ${colors.brightBlue};
    font-size: 30px;
    font-weight: 500;
    line-height: 48px;
    text-decoration: none;

    ${media.sm`
      font-size: 18px;
      line-height: 32px;
    `}
  }
`

export const BluePane = styled.div`
  position: absolute;
  bottom: 0;
  left: 15px;
  width: 70px;
  height: 500px;
  background: linear-gradient(0deg, rgba(0,168,229,0) 0%, #00A8E5 100%);

  ${media.lg`
    height: 450px;
  `} 

  ${media.md`
    display: none;
  `}
`